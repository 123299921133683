import EventProcessor from "../../../core/internal/event/EventProcessor"
import Event from "../../../core/internal/event/Event"
import { HackleUser } from "../../../core/internal/model/model"
import IdentifierUtil from "../../../core/internal/util/IdentifierUtil"
import { decorateEventWithSession, SessionManager } from "../../../core/internal/session/SessionManager"
import { UserManager } from "../../../core/internal/user/UserManager"
import { SessionEventTracker } from "../../session/track/index.browser"

export default class EventProcessorImpl implements EventProcessor {
  private delegate: EventProcessor
  private exposureEventDedupDeterminer: ExposureEventDedupDeterminer
  private sessionManager: SessionManager
  private userManager: UserManager

  constructor(
    delegate: EventProcessor,
    exposureEventDedupDeterminer: ExposureEventDedupDeterminer,
    sessionManager: SessionManager,
    userManager: UserManager
  ) {
    this.delegate = delegate
    this.exposureEventDedupDeterminer = exposureEventDedupDeterminer
    this.sessionManager = sessionManager
    this.userManager = userManager
  }

  process(event: Event): void {
    if (!SessionEventTracker.isSessionEvent(event)) {
      this.sessionManager.startNewSessionIfNeeded(this.userManager.currentUser, event.timestamp)
    }

    if (this.exposureEventDedupDeterminer.isDedupTarget(event)) {
      return
    }

    const decoratedEvent = decorateEventWithSession(event, this.sessionManager)
    this.delegate.process(decoratedEvent)
  }

  close(): void {
    this.delegate.close()
  }

  flush(): void {
    this.delegate.flush()
  }

  start(): Promise<void> {
    return this.delegate.start()
  }
}

export class ExposureEventDedupDeterminer {
  private readonly exposureEventDedupIntervalMillis: number

  private cache = new Map<string, number>()
  private currentUser?: HackleUser

  constructor(exposureEventDedupIntervalMillis: number) {
    this.exposureEventDedupIntervalMillis = exposureEventDedupIntervalMillis
  }

  isDedupTarget(event: Event): boolean {
    if (this.exposureEventDedupIntervalMillis === -1) {
      return false
    }

    if (!Event.isExposure(event)) {
      return false
    }

    if (!IdentifierUtil.isEquals(event.user.identifiers, this.currentUser?.identifiers)) {
      this.currentUser = event.user
      this.cache = new Map()
    }

    const key = [event.experiment.id, event.variationId, event.variationKey, event.decisionReason].join("-")
    const now = new Date().getTime()

    const firstExposureTimeMillis = this.cache.get(key)
    if (
      firstExposureTimeMillis !== undefined &&
      now - firstExposureTimeMillis <= this.exposureEventDedupIntervalMillis
    ) {
      return true
    }

    this.cache.set(key, now)
    return false
  }
}
