export const DEFAULT_SDK_URL = "https://client-sdk.hackle.io"

export const DEFAULT_EVENT_URL = "https://event.hackle.io"

export const DEFAULT_WORKSPACE_FETCH_URL = DEFAULT_SDK_URL + "/api/v2/workspaces"

export const WORKSPACE_FETCH_URL = DEFAULT_SDK_URL + "/api/v2/w/workspaces"

export const WORKSPACE_FETCH_URL_PREFIX = DEFAULT_SDK_URL + "/api/v2/w/"

export const WORKSPACE_FETCH_URL_POSTFIX = "/w"

export const DEFAULT_EVENT_DISPATCH_URL = DEFAULT_EVENT_URL + "/api/v2/events"

export const EVENT_DISPATCH_URL = DEFAULT_EVENT_URL + "/api/v2/w/events"

export const EVENT_DISPATCH_URL_PREFIX = DEFAULT_EVENT_URL + "/api/v2/w/"

export const EVENT_DISPATCH_URL_POSTFIX = "/events"

export const SDK_KEY_HEADER = "X-HACKLE-SDK-KEY"

export const SDK_NAME_HEADER = "X-HACKLE-SDK-NAME"

export const SDK_VERSION_HEADER = "X-HACKLE-SDK-VERSION"

export const SDK_TIME_HEADER = "X-HACKLE-SDK-TIME"

export const SDK_VERSION = "11.11.0"

export const BROWSER_MIN_POOL_INTERVAL = 30000 // 30 seconds

export const DEFAULT_POOL_INTERVAL = 10000

export const REQUEST_TIME_OUT = 10000

export const DEFAULT_BATCH_SIZE = 1000

export const BROWSER_BATCH_SIZE = 100

export const DEFAULT_FLUSH_INTERVAL = 10000

export const BROWSER_FLUSH_INTERVAL = 500 // milliseconds

export const DEFAULT_ON_READY_TIMEOUT = 3000

export const ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT = [0, 8, 16, 32, 64, 128, 256, 512]

export const COOKIE_EXPIRE_DAYS = 365 * 10

export const COOKIE_HID_KEY = "_hackle_hid"

export const COOKIE_USER_ID_KEY = "_hackle_id"

export const LOCAL_STORAGE_KEY_PREFIX = "hackle-repository-storage"

export const DEFAULT_SESSION_TIMEOUT_MILLIS = 1000 * 60 * 30 // 30m

export const SESSION_ID_STORAGE_KEY = "_hackle_session_id"
export const LAST_EVENT_TIME_STORAGE_KEY = "_hackle_last_event_ts"

export const USER_ID_STORAGE_KEY = "_hackle_uid"
export const DEVICE_ID_STORAGE_KEY = "_hackle_did"
