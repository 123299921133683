import {
  DecisionReason,
  HackleRemoteConfig,
  MatchValueType,
  RemoteConfigDecision,
  User
} from "../../core/internal/model/model"
import HackleInternalClient from "../../core/internal/client/HackleInternalClient"
import { UserManager } from "../../core/internal/user/UserManager"
import { HackleUserResolver } from "../user/index.browser"
import ObjectUtil from "../../core/internal/util/ObjectUtil"
import Logger from "../../core/internal/logger"

const log = Logger.log

export default class HackleRemoteConfigImpl implements HackleRemoteConfig {

  constructor(
    private readonly client: HackleInternalClient,
    private readonly userManager: UserManager,
    private readonly hackleUserResolver: HackleUserResolver,
    private readonly user?: User
  ) {
  }

  get(key: string, defaultValue: string): string
  get(key: string, defaultValue: number): number
  get(key: string, defaultValue: boolean): boolean
  get(key: string, defaultValue: string | number | boolean): string | number | boolean {
    if (ObjectUtil.isNullOrUndefined(defaultValue)) {
      return this._get(key, "NULL", defaultValue).value
    }

    switch (typeof defaultValue) {
      case "string":
        return this._get(key, "STRING", defaultValue).value
      case "number":
        return this._get(key, "NUMBER", defaultValue).value
      case "boolean":
        return this._get(key, "BOOLEAN", defaultValue).value
      default:
        return this._get(key, "UNKNOWN", defaultValue).value
    }
  }

  private _get(key: string, requiredType: MatchValueType, defaultValue: string | number | boolean): RemoteConfigDecision {
    try {

      if (ObjectUtil.isNullOrUndefined(key)) {
        return RemoteConfigDecision.of(defaultValue, DecisionReason.INVALID_INPUT)
      }
      const hackleUser = this.hackleUserResolver.resolveCurrentUser()
      return this.client._remoteConfig(key, hackleUser, requiredType, defaultValue)
    } catch (e) {
      log.error(`Unexpected exception while deciding remote config parameter[${key}]. Returning default value. : ${e}`)
      return RemoteConfigDecision.of(defaultValue, DecisionReason.EXCEPTION)
    }
  }
}
