import {
  DecisionReason,
  Experiment,
  HackleUser,
  Long,
  MatchValueType,
  ParameterConfiguration,
  Properties,
  RemoteConfigParameter,
  RemoteConfigParameterValue,
  Variation,
  VariationId,
  VariationKey
} from "../model/model"
import EvaluationFlowFactory from "./flow/EvaluationFlowFactory"
import Workspace from "../workspace/Workspace"
import ObjectUtil from "../util/ObjectUtil"

export class Evaluation {
  variationId: VariationId | undefined
  variationKey: VariationKey
  reason: DecisionReason
  config: ParameterConfiguration | undefined

  constructor(
    variationId: VariationId | undefined,
    variationKey: VariationKey,
    reason: DecisionReason,
    config: ParameterConfiguration | undefined
  ) {
    this.variationId = variationId
    this.variationKey = variationKey
    this.reason = reason
    this.config = config
  }

  static of(
    workspace: Workspace,
    experiment: Experiment,
    variationKey: VariationKey,
    reason: DecisionReason
  ): Evaluation {
    const variation = experiment._getVariationByKeyOrNull(variationKey)
    if (ObjectUtil.isNotNullOrUndefined(variation)) {
      return Evaluation.withVariation(workspace, variation, reason)
    } else {
      return new Evaluation(undefined, variationKey, reason, undefined)
    }
  }

  static withVariation(workspace: Workspace, variation: Variation, reason: DecisionReason): Evaluation {
    const config = this.config(workspace, variation)
    return new Evaluation(variation.id, variation.key, reason, config)
  }

  private static config(workspace: Workspace, variation: Variation): ParameterConfiguration | undefined {
    if (ObjectUtil.isNullOrUndefined(variation.parameterConfigurationId)) {
      return undefined
    }

    return ObjectUtil.requiredNotNullOrUndefined(
      workspace.getParameterConfigurationOrNull(variation.parameterConfigurationId),
      () => `ParameterConfiguration[${variation.parameterConfigurationId}]`
    )
  }
}

export class RemoteConfigEvaluation {
  valueId: Long | undefined
  value: string | number | boolean
  reason: DecisionReason
  properties: Properties

  constructor(
    valueId: Long | undefined,
    value: string | number | boolean,
    reason: DecisionReason,
    properties: Properties
  ) {
    this.valueId = valueId
    this.value = value
    this.reason = reason
    this.properties = properties
  }
}

export default class Evaluator {
  private evaluationFlowFactory: EvaluationFlowFactory

  constructor(evaluationFlowFactory: EvaluationFlowFactory) {
    this.evaluationFlowFactory = evaluationFlowFactory
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: HackleUser,
    defaultVariationKey: VariationKey
  ): Evaluation {
    const evaluationFlow = this.evaluationFlowFactory.getFlow(experiment.type)
    return evaluationFlow.evaluate(workspace, experiment, user, defaultVariationKey)
  }

  remoteConfigEvaluate(
    workspace: Workspace,
    remoteConfigParameter: RemoteConfigParameter,
    user: HackleUser,
    requiredType: MatchValueType,
    defaultValue: string | number | boolean
  ): RemoteConfigEvaluation {
    let properties: Properties = {}
    properties["requestValueType"] = requiredType
    properties["requestDefaultValue"] = defaultValue

    if (ObjectUtil.isNullOrUndefined(user.identifiers[remoteConfigParameter.identifierType])) {
      return new RemoteConfigEvaluation(undefined, defaultValue, DecisionReason.IDENTIFIER_NOT_FOUND, properties)
    }

    const targetRuleDeterminer = this.evaluationFlowFactory.getRemoteConfigParameterTargetRuleDeterminer()
    const targetRule = targetRuleDeterminer.determineTargetRuleOrNull(workspace, remoteConfigParameter, user)
    if (targetRule) {
      properties["targetRuleKey"] = targetRule.key
      properties["targetRuleName"] = targetRule.name
      return this.getRemoteConfigEvaluationWithTypeCheck(
        targetRule.value,
        DecisionReason.TARGET_RULE_MATCH,
        requiredType,
        defaultValue,
        properties
      )
    }

    return this.getRemoteConfigEvaluationWithTypeCheck(
      remoteConfigParameter.defaultValue,
      DecisionReason.DEFAULT_RULE,
      requiredType,
      defaultValue,
      properties
    )
  }

  private getRemoteConfigEvaluationWithTypeCheck(
    remoteConfigParameterValue: RemoteConfigParameterValue,
    reason: DecisionReason,
    requiredType: MatchValueType,
    defaultValue: string | number | boolean,
    properties: Properties
  ): RemoteConfigEvaluation {
    if (
      requiredType === "UNKNOWN" ||
      (requiredType !== "NULL" && typeof defaultValue !== typeof remoteConfigParameterValue.rawValue)
    ) {
      properties["returnValue"] = defaultValue
      return new RemoteConfigEvaluation(undefined, defaultValue, DecisionReason.TYPE_MISMATCH, properties)
    }

    properties["returnValue"] = remoteConfigParameterValue.rawValue
    return new RemoteConfigEvaluation(
      remoteConfigParameterValue.id,
      remoteConfigParameterValue.rawValue,
      reason,
      properties
    )
  }
}
