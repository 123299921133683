import Logger from "../core/internal/logger"
import HackleClient from "../core/HackleClient"
import HackleInternalClient from "../core/internal/client/HackleInternalClient"
import { HackleUserResolver } from "./user/index.browser"
import {
  Decision,
  DecisionReason,
  FeatureFlagDecision,
  HackleEvent,
  HackleRemoteConfig,
  Properties,
  sanitizeUser,
  User,
  VariationKey
} from "../core/internal/model/model"
import { DEFAULT_ON_READY_TIMEOUT } from "../config"
import { SessionManagerImpl } from "./session/manager/index.browser"
import { UserManagerImpl } from "./user/manager/index.browser"
import HackleRemoteConfigImpl from "./remoteconfig/index.browser"
import PropertyUtil from "../core/internal/util/PropertyUtil"
import IdentifierUtil from "../core/internal/util/IdentifierUtil"

const log = Logger.log

export interface PageView {
  user?: User
  pathUrl?: string
}

export interface BrowserHackleClient extends HackleClient {

  /**
   * Returns current session ID
   *
   * @return {String} if session is unavailable, returns 0.ffffffff
   * @since 11.8.0
   *
   */
  getSessionId(): string

  getUser(): User

  setUser(user: User): void

  setUserId(userId: string | undefined): void

  setDeviceId(deviceId: string): void

  setUserProperty(key: string, value: any): void

  setUserProperties(properties: Properties): void

  resetUser(): void

  /**
   * Determine the variation to expose to the user for experiment.
   *
   * This method return the {"A"} if:
   * - The experiment key is invalid
   * - The experiment has not started yet
   * - The user is not allocated to the experiment
   * - The determined variation has been dropped
   *
   * @param experimentKey     the unique key of the experiment.
   * @param user              the user to participate in the experiment. MUST NOT be null.
   * @param defaultVariation  the default variation of the experiment.
   *
   * @return string the decided variation for the user, or the default variation.
   */
  variation(experimentKey: number, user?: User | string, defaultVariation?: string): string

  /**
   * Determine the variation to expose to the user for experiment, and returns an object that
   * describes the way the variation was determined.
   *
   * @param experimentKey    the unique key of the experiment.
   * @param user             the user to participate in the experiment. MUST NOT be null. (e.g. { id: "userId"} )
   * @param defaultVariation the default variation of the experiment. MUST NOT be null.
   *
   * @return {Decision} object
   */
  variationDetail(experimentKey: number, user?: User | string, defaultVariation?: string): Decision

  /**
   * Determine whether the feature is turned on to the user.
   *
   * @param featureKey the unique key for the feature.
   * @param user       the user requesting the feature.
   *
   * @return boolean True if the feature is on. False if the feature is off.
   *
   * @since 2.0.0
   */
  isFeatureOn(featureKey: number, user?: User | string): boolean

  /**
   * Determine whether the feature is turned on to the user, and returns an object that
   * describes the way the value was determined.
   *
   * @param featureKey the unique key for the feature.
   * @param user     the identifier of user.
   *
   * @return {FeatureFlagDecision}
   *
   * @since 2.0.0
   */
  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision

  /**
   * Records the event performed by the user.
   *
   * @param event the unique key of the event. MUST NOT be null.
   * @param user the identifier of user that performed the event. id MUST NOT be null. (e.g. { id: "userId"} )
   */
  track(event: HackleEvent | string, user?: User | string): void

  trackPageView(option?: PageView): void

  /**
   * Return a instance of Hackle Remote Config.
   *
   * @param user     the identifier of user.
   */
  remoteConfig(user?: User | string): HackleRemoteConfig

  onReady(block: () => void, timeout?: number): void
}

export default class HackleClientImpl implements BrowserHackleClient {
  private hackleInternalClient: HackleInternalClient
  private hackleUserResolver: HackleUserResolver
  private sessionManager: SessionManagerImpl
  private userManager: UserManagerImpl

  constructor(
    hackleInternalClient: HackleInternalClient,
    hackleUserResolver: HackleUserResolver,
    sessionManager: SessionManagerImpl,
    userManager: UserManagerImpl
  ) {
    this.hackleInternalClient = hackleInternalClient
    this.hackleUserResolver = hackleUserResolver
    this.sessionManager = sessionManager
    this.userManager = userManager
  }

  getSessionId(): string {
    return this.sessionManager.sessionId
  }

  getUser(): User {
    return this.userManager.currentUser
  }

  setUser(user: User): void {
    try {
      const sanitizedUser = sanitizeUser(user)
      if (!sanitizedUser) {
        log.warn("invalid user")
        return
      }
      this.userManager.setUser(sanitizedUser)
    } catch (e) {
      log.error(`Unexpected exception while set user: ${e}`)
    }
  }

  setUserId(userId: string | number | undefined): void {
    try {
      if (userId === undefined) {
        this.userManager.setUserId(undefined)
      }
      const sanitizedUserId = IdentifierUtil.sanitizeValue(userId)
      if (!sanitizedUserId) {
        log.warn(`Invalid userId. [userId=${userId}]`)
        return
      }
      this.userManager.setUserId(sanitizedUserId)
    } catch (e) {
      log.error(`Unexpected exception while set userId: ${e}`)
    }
  }

  setDeviceId(deviceId: string): void {
    try {
      const sanitizedDeviceId = IdentifierUtil.sanitizeValue(deviceId)
      if (!sanitizedDeviceId) {
        log.warn(`Invalid deviceId. [deviceId=${deviceId}]`)
        return
      }
      this.userManager.setDeviceId(deviceId)
    } catch (e) {
      log.error(`Unexpected exception while set deviceId: ${e}`)
    }
  }

  setUserProperty(key: string, value: any): void {
    try {
      if (!PropertyUtil.isValidKey(key)) {
        log.warn(`Invalid property key. [key=${key}]`)
        return
      }

      if (!PropertyUtil.isValidValue(value)) {
        log.warn(`Invalid property value. [key=${value}]`)
        return
      }

      this.userManager.setUserProperty(key, value)
    } catch (e) {
      log.error(`Unexpected exception while set userProperty: ${e}`)
    }
  }

  setUserProperties(properties: Properties): void {
    try {
      const sanitizedProperties = PropertyUtil.sanitize(properties)
      this.userManager.setUserProperties(sanitizedProperties)
    } catch (e) {
      log.error(`Unexpected exception while set userProperties: ${e}`)
    }
  }

  resetUser(): void {
    try {
      this.userManager.resetUser()
    } catch (e) {
      log.error(`Unexpected exception while reset user: ${e}`)
    }
  }

  variation(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): string {
    return this.variationDetail(experimentKey, user, defaultVariation).variation
  }

  variationDetail(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): Decision {
    const defaultVariationKey = defaultVariation || "A"
    try {
      const currentUser = this.userManager.resolveCurrentOrNull(user)
      if (!currentUser) {
        log.warn(`invalid user`)
        return Decision.of(defaultVariationKey, DecisionReason.INVALID_INPUT)
      }
      const hackleUser = this.hackleUserResolver.resolve(currentUser)
      return this.hackleInternalClient._experiment(experimentKey, hackleUser, defaultVariationKey)
    } catch (e) {
      log.error(`Unexpected exception while deciding variation for experiment[${experimentKey}]. Returning default variation[${defaultVariationKey}] : ${e}`)
      return Decision.of(defaultVariationKey, DecisionReason.EXCEPTION)
    }
  }

  isFeatureOn(featureKey: number, user?: User | string): boolean {
    return this.featureFlagDetail(featureKey, user).isOn
  }

  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision {
    try {
      const currentUser = this.userManager.resolveCurrentOrNull(user)
      if (!currentUser) {
        log.warn(`invalid user`)
        return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
      }
      const hackleUser = this.hackleUserResolver.resolve(currentUser)
      return this.hackleInternalClient._featureFlag(featureKey, hackleUser)
    } catch (e) {
      log.error(`Unexpected exception while deciding feature flag[${featureKey}]. Returning default value[false] : ${e}`)
      return FeatureFlagDecision.off(DecisionReason.EXCEPTION)
    }
  }

  track(event: HackleEvent | string, user?: User | string) {
    try {
      log.debug(`track event : ${JSON.stringify(event)}`)
      const hackleEvent = this._convertEvent(event)
      const currentUser = this.userManager.resolveCurrentOrNull(user)
      if (!currentUser) {
        log.warn(`invalid user`)
        return
      }
      const hackleUser = this.hackleUserResolver.resolve(currentUser)
      this.hackleInternalClient._track(hackleEvent, hackleUser)
    } catch (e) {
      log.error(`Unexpected exception while tracking event: $t`)
    }
  }

  trackPageView(option?: PageView): void {
    log.debug("tracking page view")
    const hackleEvent = { key: "$page_view" }
    const hackleUser = this.hackleUserResolver.resolve(this.userManager.currentUser)
    this.hackleInternalClient._track(hackleEvent, hackleUser)
  }

  remoteConfig(user?: User): HackleRemoteConfig {
    return new HackleRemoteConfigImpl(this.hackleInternalClient, this.userManager, this.hackleUserResolver, user)
  }

  onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this.hackleInternalClient._onReady(block, timeout)
  }

  onInitialized(config?: { timeout?: number }): Promise<{ success: boolean }> {
    return this.hackleInternalClient._onInitialized({ timeout: config?.timeout })
  }

  close(): void {
    log.debug("Hackle Client is closing")
    this.hackleInternalClient._close()
  }

  private _convertEvent(event: HackleEvent | string): HackleEvent {
    if (typeof event === "string") {
      return { key: event }
    }
    return event
  }
}
