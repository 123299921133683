export interface IStorage {
  getItem(key: string): string | null
  setItem(key: string, value: string): void
  removeItem(key: string): void
}

export class KeyTransformStorage implements IStorage {

  private readonly delegate: IStorage
  private readonly transform: (key: string) => string

  constructor(delegate: IStorage, transform: (key: string) => string) {
    this.delegate = delegate
    this.transform = transform
  }

  static postfix(delegate: IStorage, postfix: string): KeyTransformStorage {
    return new KeyTransformStorage(delegate, (key) => `${key}${postfix}`)
  }

  getItem(key: string): string | null {
    return this.delegate.getItem(this.transform(key))
  }

  removeItem(key: string): void {
    this.delegate.removeItem(this.transform(key))
  }

  setItem(key: string, value: string): void {
    this.delegate.setItem(this.transform(key), value)
  }
}
