import OperatorMatcher from "./OperatorMatcher"
import { MatchValueType, Version } from "../../model/model"
import ObjectUtil from "../../util/ObjectUtil"

export default interface ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean
}

export class StringMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const typedUserValue = this.asString(userValue)
    const typedMatchValue = this.asString(matchValue)
    if (ObjectUtil.isNullOrUndefined(typedUserValue) || ObjectUtil.isNullOrUndefined(typedMatchValue)) {
      return false
    }
    return operatorMatcher.stringMatches(typedUserValue, typedMatchValue)
  }

  private asString(value: any): string | null {
    if (typeof value === "string") {
      return value
    }

    if (typeof value === "number") {
      return value.toString()
    }

    return null
  }
}

export class NumberMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const typedUserValue = this.asNumber(userValue)
    const typedMatchValue = this.asNumber(matchValue)
    if (ObjectUtil.isNullOrUndefined(typedUserValue) || ObjectUtil.isNullOrUndefined(typedMatchValue)) {
      return false
    }
    return operatorMatcher.numberMatches(typedUserValue, typedMatchValue)
  }

  private asNumber(value: any): number | null {
    if (typeof value === "number") {
      return value
    }

    if (typeof value === "string") {
      const number = Number(value)
      if (isNaN(number)) {
        return null
      } else {
        return number
      }
    }

    return null
  }
}

export class BooleanMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    if (typeof userValue === "boolean" && typeof matchValue === "boolean") {
      return operatorMatcher.booleanMatches(userValue, matchValue)
    } else {
      return false
    }
  }
}

export class VersionMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    const userVersion = Version.tryParse(userValue)
    const matchVersion = Version.tryParse(matchValue)
    if (userVersion && matchVersion) {
      return operatorMatcher.versionMatches(userVersion, matchVersion)
    } else {
      return false
    }
  }
}

export class NullMatcher implements ValueMatcher {
  matches(operatorMatcher: OperatorMatcher, userValue: any, matchValue: any): boolean {
    return false
  }
}

export class ValueMatcherFactory {
  private static STRING_MATCHER = new StringMatcher()
  private static NUMBER_MATCHER = new NumberMatcher()
  private static BOOLEAN_MATCHER = new BooleanMatcher()
  private static VERSION_MATCHER = new VersionMatcher()
  private static NULL_MATCHER = new NullMatcher()

  getMatcher(valueType: MatchValueType): ValueMatcher {
    switch (valueType) {
      case "STRING":
        return ValueMatcherFactory.STRING_MATCHER
      case "JSON":
        return ValueMatcherFactory.STRING_MATCHER
      case "NUMBER":
        return ValueMatcherFactory.NUMBER_MATCHER
      case "BOOLEAN":
        return ValueMatcherFactory.BOOLEAN_MATCHER
      case "VERSION":
        return ValueMatcherFactory.VERSION_MATCHER
      case "NULL":
        return ValueMatcherFactory.NULL_MATCHER
      case "UNKNOWN":
        return ValueMatcherFactory.NULL_MATCHER
    }
  }
}
