import { COOKIE_EXPIRE_DAYS } from "../../config"
import { IStorage } from "../../core/internal/storage/Storage"

export interface CookieStorageOptions {
  days?: number
  isCrossSubdomain?: boolean
  isSecure?: boolean
  isCrossSite?: boolean
}

export class CookieStorage implements IStorage {
  private static extractDomain(hostname: string): string {
    const DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i
    const SIMPLE_DOMAIN_MATCH_REGEX = /[a-z0-9][a-z0-9-]*\.[a-z]+$/i

    let domain_regex = DOMAIN_MATCH_REGEX

    const parts = hostname.split(".")
    const tld = parts[parts.length - 1]
    if (tld.length > 4 || tld === "com" || tld === "org") {
      domain_regex = SIMPLE_DOMAIN_MATCH_REGEX
    }

    const matches = hostname.match(domain_regex)
    return matches ? matches[0] : ""
  }

  constructor(
    private options: CookieStorageOptions = {
      days: 0,
      isCrossSubdomain: false,
      isSecure: false,
      isCrossSite: false
    }
  ) {}

  public getItem(key: string): string | null {
    const nameEQ = key + "="

    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length))
      }
    }
    return null
  }

  public setItem(key: string, value: string, options?: CookieStorageOptions): string {
    const optionsEffective = {
      ...this.options,
      ...options
    }
    let { isSecure } = optionsEffective

    let cdomain = "",
      expires = "",
      secure = ""

    if (optionsEffective.isCrossSubdomain) {
      const domain = CookieStorage.extractDomain(document.location.hostname)
      cdomain = domain ? "; domain=." + domain : ""
    }

    if (optionsEffective.days) {
      const date = new Date()
      date.setTime(date.getTime() + optionsEffective.days * 24 * 60 * 60 * 1000)
      expires = "; expires=" + date.toUTCString()
    }

    if (optionsEffective.isCrossSite) {
      isSecure = true
      secure = "; SameSite=None"
    }
    if (isSecure) {
      secure += "; secure"
    }

    const new_cookie_val = key + "=" + encodeURIComponent(value) + expires + "; path=/" + cdomain + secure

    document.cookie = new_cookie_val
    return new_cookie_val
  }

  public removeItem(key: string, options?: CookieStorageOptions): void {
    this.setItem(key, "", { ...options, days: -1 })
  }
}

export const cookieStorage = new CookieStorage({ days: COOKIE_EXPIRE_DAYS, isCrossSubdomain: true })
