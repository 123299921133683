import EventProcessor from "../event/EventProcessor"
import {
  Decision,
  DecisionReason,
  EmptyParameterConfig,
  EventType,
  FeatureFlagDecision,
  HackleEvent,
  HackleUser,
  ParameterConfig,
  VariationKey,
  MatchValueType,
  RemoteConfigDecision
} from "../model/model"
import Event from "../event/Event"
import Logger from "../logger"
import WorkspaceFetcher from "../workspace/WorkspaceFetcher"
import Evaluator from "../evaluation/Evaluator"
import { DEFAULT_ON_READY_TIMEOUT } from "../../../config"
import ObjectUtil from "../util/ObjectUtil"
import TraceTransformer from "../trace/TraceTransformer"

const log = Logger.log

export default class HackleInternalClient {
  private workspaceFetcher: WorkspaceFetcher
  private eventProcessor: EventProcessor
  private evaluator: Evaluator
  private errorDedupDeterminer: ErrorDedupDeterminer
  private readonly readyPromise: any

  constructor(
    workspaceFetcher: WorkspaceFetcher,
    eventProcessor: EventProcessor,
    evaluator: Evaluator,
    errorDedupDeterminer: ErrorDedupDeterminer
  ) {
    this.workspaceFetcher = workspaceFetcher
    this.eventProcessor = eventProcessor
    this.evaluator = evaluator
    this.errorDedupDeterminer = errorDedupDeterminer
    this.workspaceFetcher.start()
    this.eventProcessor.start()
    this.readyPromise = this.workspaceFetcher.onReady().then(
      () => {
        return { success: true }
      },
      (error: any) => {
        return { success: false, error: error }
      }
    )
  }

  _experiment(experimentKey: number, user: HackleUser, defaultVariation: VariationKey): Decision {
    if (!experimentKey) {
      log.error("experimentKey must not be empty")
      return Decision.of(defaultVariation, DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return Decision.of(defaultVariation, DecisionReason.SDK_NOT_READY)
    }

    const experiment = workspace.getExperimentOrNull(experimentKey)

    if (!experiment) {
      log.warn("Experiment does not exist.")
      return Decision.of(defaultVariation, DecisionReason.EXPERIMENT_NOT_FOUND)
    }

    const evaluation = this.evaluator.evaluate(workspace, experiment, user, defaultVariation)
    this.eventProcessor.process(Event.exposure(experiment, user, evaluation))

    const config: ParameterConfig = evaluation.config ?? new EmptyParameterConfig()
    return Decision.of(evaluation.variationKey, evaluation.reason, config)
  }

  _featureFlag(featureKey: number, user: HackleUser): FeatureFlagDecision {
    if (!featureKey) {
      log.error("featureKey must not be empty")
      return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return FeatureFlagDecision.off(DecisionReason.SDK_NOT_READY)
    }

    const featureFlag = workspace.getFeatureFlagOrNull(featureKey)

    if (!featureFlag) {
      log.warn("FeatureFlag does not exist.")
      return FeatureFlagDecision.off(DecisionReason.FEATURE_FLAG_NOT_FOUND)
    }

    const evaluation = this.evaluator.evaluate(workspace, featureFlag, user, "A")
    this.eventProcessor.process(Event.exposure(featureFlag, user, evaluation))

    const config: ParameterConfig = evaluation.config ?? new EmptyParameterConfig()
    if (evaluation.variationKey === "A") {
      return FeatureFlagDecision.off(evaluation.reason, config)
    } else {
      return FeatureFlagDecision.on(evaluation.reason, config)
    }
  }

  _track(event: HackleEvent, user: HackleUser, timestamp: number = new Date().getTime()) {
    if (!event) {
      log.warn("event must not be null.")
      return
    }

    if (typeof event !== "object") {
      log.warn("Event must be event type.")
      return
    }

    if (typeof event === "object") {
      if (!event.key || typeof event.key !== "string") {
        log.warn("Event key must be not null. or event key must be string type.")
        return
      }
    }

    const eventType = this.workspaceFetcher.get()?.getEventTypeOrNull(event.key) || new EventType(0, event.key)
    this.eventProcessor.process(Event.track(eventType, event, user, timestamp))
  }

  _trackException(exception: Error, user: HackleUser) {
    if (this.errorDedupDeterminer.isDedupTarget(exception)) {
      return
    }

    const event = TraceTransformer.toEvent(exception)

    this._flush()
    this._track(event, user)
    this._flush()
  }

  _flush() {
    this.eventProcessor.flush()
  }

  _remoteConfig(
    parameterKey: string,
    user: HackleUser,
    requiredType: MatchValueType,
    defaultValue: string | number | boolean
  ): RemoteConfigDecision {
    const workspace = this.workspaceFetcher.get()

    if (ObjectUtil.isNullOrUndefined(workspace)) {
      log.warn("SDK not ready.")
      return RemoteConfigDecision.of(defaultValue, DecisionReason.SDK_NOT_READY)
    }

    const remoteConfigParameter = workspace.getRemoteConfigParameterOrNull(parameterKey)

    if (ObjectUtil.isNullOrUndefined(remoteConfigParameter)) {
      log.warn("Remote config parameter does not exist.")
      return RemoteConfigDecision.of(defaultValue, DecisionReason.REMOTE_CONFIG_PARAMETER_NOT_FOUND)
    }

    const evaluation = this.evaluator.remoteConfigEvaluate(
      workspace,
      remoteConfigParameter,
      user,
      requiredType,
      defaultValue
    )
    this.eventProcessor.process(Event.remoteConfig(remoteConfigParameter, user, evaluation))

    return RemoteConfigDecision.of(evaluation.value, evaluation.reason)
  }

  _onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this._onInitialized({ timeout: timeout }).then(() => block())
  }

  _onInitialized({ timeout = DEFAULT_ON_READY_TIMEOUT }: { timeout?: number }): Promise<{ success: boolean }> {
    log.debug("Start HackleClient initializing")
    let resolveTimeoutPromise: any
    const timeoutPromise: Promise<{ success: boolean }> = new Promise((resolve) => {
      resolveTimeoutPromise = resolve
    })

    const onReadyTimeout = () => {
      resolveTimeoutPromise({
        success: false
      })
    }

    const readyTimeout = setTimeout(onReadyTimeout, timeout)

    this.readyPromise.then(
      () => {
        clearTimeout(readyTimeout)
        resolveTimeoutPromise({
          success: true
        })
      },
      (error: any) => {
        clearTimeout(readyTimeout)
        resolveTimeoutPromise({
          success: false,
          error: error
        })
      }
    )

    return Promise.race([this.readyPromise, timeoutPromise]).then((result: { success: boolean; error?: any }) => {
      if (result.success) {
        log.debug("HackleClient onInitialized Success")
      } else {
        if (result.error instanceof Error) {
          log.error(`HackleClient onInitialized Failed. ${result.error.message}`)
        } else {
          log.error(`HackleClient onInitialized Failed. ${result.error}`)
        }
      }
      return Promise.resolve({ success: result.success })
    })
  }

  _close(): void {
    this.workspaceFetcher.close()
    this.eventProcessor.close()
  }
}

export class ErrorDedupDeterminer {
  private previous?: Error

  isDedupTarget(error: Error): boolean {
    if (this._isSameError(error, this.previous)) {
      return true
    }
    this.previous = error
    return false
  }

  _isSameError(currentError: Error, previousError?: Error): boolean {
    if (ObjectUtil.isNullOrUndefined(previousError)) {
      return false
    }
    return (
      currentError.name === previousError.name &&
      currentError.message === previousError.message &&
      currentError.stack === previousError.stack
    )
  }
}
