import { Properties, PropertyValue } from "../model/model"
import ObjectUtil from "./ObjectUtil"
import Logger from "../logger"

const log = Logger.log

export default class PropertyUtil {
  private static SYSTEM_PROPERTY_KEY_PREFIX = "$"
  private static MAX_PROPERTIES_COUNT = 128
  private static MAX_PROPERTY_KEY_LENGTH = 128
  private static MAX_PROPERTY_VALUE_LENGTH = 1024

  static sanitize(properties: { [key: string]: any } | undefined | null): Properties {
    const sanitized: Properties = {}

    if (ObjectUtil.isNullOrUndefined(properties)) {
      return sanitized
    }

    for (let [key, value] of Object.entries(properties)) {
      if (Object.keys(sanitized).length >= this.MAX_PROPERTIES_COUNT) {
        log.debug(`The number of properties cannot exceed 128. Drops excess properties. [${key}, ${value}]`)
        break
      }

      if (!this.isValidKey(key)) {
        log.debug(`Invalid property key. The property key must be a string of 128 characters or less. [${key}]`)
        continue
      }

      const sanitizedValue = this.sanitizeValue(key, value)
      if (ObjectUtil.isNullOrUndefined(sanitizedValue)) {
        log.debug(
          `Invalid property value. The property value mus be 'string(..1024)', 'number', 'boolean', or 'array' type. [${value}]`
        )
        continue
      }

      sanitized[key] = sanitizedValue
    }
    return sanitized
  }

  static isValidProperty(key: any, value: any): boolean {
    return this.isValidKey(key) && this.isValidValue(value)
  }

  static isValidKey(key: any): key is string {
    return (
      ObjectUtil.isNotNullOrUndefined(key) &&
      typeof key === "string" &&
      key.length > 0 &&
      key.length <= this.MAX_PROPERTY_KEY_LENGTH
    )
  }

  static sanitizeValue(key: string, value: any): PropertyValue | null {
    if (key.startsWith(this.SYSTEM_PROPERTY_KEY_PREFIX)) {
      return value
    }

    if (!this.isValidValue(value)) {
      return null
    }

    if (Array.isArray(value)) {
      return value.filter((it) => this.isValidElement(it))
    }

    return value
  }

  static isValidValue(value: any): value is PropertyValue {
    if (ObjectUtil.isNullOrUndefined(value)) {
      return false
    }

    if (typeof value === "string") {
      return value.length <= this.MAX_PROPERTY_VALUE_LENGTH
    }

    if (typeof value === "number") {
      return !isNaN(value) && isFinite(value)
    }

    return typeof value === "boolean" || Array.isArray(value)
  }

  private static isValidElement(element: any): element is string | number {
    if (ObjectUtil.isNullOrUndefined(element)) {
      return false
    }

    if (typeof element === "string") {
      return element.length > 0 && element.length <= this.MAX_PROPERTY_VALUE_LENGTH
    }

    return typeof element === "number"
  }
}
